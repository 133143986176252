import { Component } from "react";
import "./HolisticContainer.scss";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Link} from 'react-router-dom'
import cn from 'classnames';
import { Holistic, POSE_CONNECTIONS, FACEMESH_TESSELATION, HAND_CONNECTIONS } from '@mediapipe/holistic/holistic'
import { drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils'
import { Camera } from '@mediapipe/camera_utils'


function HolisticContainer() {
    //
    const inputRef = useRef(null);
    const outputRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [canvasWidth, setCanvasWidth] = useState(null);
    const [canvasHeight, setCanvasHeight] = useState(null);
    //



    useEffect(() => {
     console.log( inputRef, outputRef)
     // Prefer camera resolution nearest to 1280x720.

     setCanvas(outputRef.current.getContext('2d'));
    }, [] )

    //if canvas start the script
    useEffect(() => {

     if(canvas){
       console.log("canvas size", outputRef.current.getBoundingClientRect().width)
       setCanvasWidth(outputRef.current.getBoundingClientRect().width)
       setCanvasHeight(outputRef.current.getBoundingClientRect().width)


     }

    }, [canvas] )

    //if canvas size
    useEffect(() => {

     if(canvasWidth > 0){

       var constraints = { audio: true, video: { width: 1280, height: 720 } };

       navigator.mediaDevices.getUserMedia(constraints)
       .then(function(mediaStream) {
         console.log("hay video", mediaStream);
         startScript();
       })
       .catch(function(err) { console.log(err.name + ": " + err.message); }); // always check for errors at the end.




     }

    }, [canvasWidth] )


    //Start Holistic & camera
    const startScript=()=>
    {

      const holistic = new Holistic({locateFile: (file) => {
        return `./mediapipe/holistic/${file}`;

      }});
      holistic.setOptions({
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: true,
        smoothSegmentation: true,
        refineFaceLandmarks: true,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5
      });

      holistic.onResults(onResults);

      const camera = new Camera(inputRef.current, {
       onFrame: async () => {
         //console.log("frame", inputRef.current);

         await holistic.send({image: inputRef.current});
       },
       width: window.innerWidth,
       height: window.innerHeight
     });
     camera.start();
    }

    //Holistics results

    const onResults=(results)=>
    {


      canvas.save();
      canvas.clearRect(0, 0, outputRef.current.width, outputRef.current.height);
      canvas.drawImage(results.segmentationMask, 0, 0,
                      outputRef.current.width, outputRef.current.height);

      // Only overwrite existing pixels.
      canvas.globalCompositeOperation = 'source-in';
      canvas.fillStyle = '#00FF0066';
      canvas.fillRect(0, 0, outputRef.current.width, outputRef.current.height);

      // Only overwrite missing pixels.
      canvas.globalCompositeOperation = 'destination-atop';
      canvas.drawImage(
      results.image, 0, 0, outputRef.current.width, outputRef.current.height);
      canvas.globalCompositeOperation = 'source-over';
 drawConnectors(canvas, results.poseLandmarks, POSE_CONNECTIONS,
                {color: '#FF0000', lineWidth: 4});
 drawLandmarks(canvas, results.poseLandmarks,
               {color: '#FF0000', lineWidth: 2});
 drawConnectors(canvas, results.faceLandmarks, FACEMESH_TESSELATION,
                {color: '#000000', lineWidth: 1});
 drawConnectors(canvas, results.leftHandLandmarks, HAND_CONNECTIONS,
                {color: '#FF0000', lineWidth: 5});
 drawLandmarks(canvas, results.leftHandLandmarks,
               {color: '#FF0000', lineWidth: 2});
 drawConnectors(canvas, results.rightHandLandmarks, HAND_CONNECTIONS,
                {color: '#FF0000', lineWidth: 5});
 drawLandmarks(canvas, results.rightHandLandmarks,
               {color: '#FF0000', lineWidth: 2});
      canvas.restore();
    //  console.log("onresults", results)
    }

    return (
      <div className={  cn({
          ["HolisticContainer"]: true,
        })}>

        <div className="container">

          <video className="input_video" ref={inputRef}></video>
          <canvas width={canvasWidth} height={canvasHeight} className="output_canvas" ref={outputRef}> </canvas>
        </div>

      </div>
    );

}
export default HolisticContainer;
